@import '../../assets/sass/theme';

.ProgressBar {
  width: 100%;
  height: 8px;
  background-color: $blaise-light-gray;
  border-radius: 5px;
}

.Progress {
  background-color: $blaise-green;
  height: 100%;
  margin: 0;
  border-radius: 5px;
}
