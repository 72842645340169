@import '../../assets/sass/theme';

.ticket-bundle {
  width: 100%;
  background-color: $gray-200 !important;
  display: flex !important;
  justify-content: space-between !important;
  padding: 25px 24px !important;
  border: 2px solid transparent !important;
}

.selected-ticket-bundle {
  background-color: #02cdac26 !important;
  border: 2px solid $blaise-green !important;
  &:hover {
    background-color: #02cdac26;
  }
}
