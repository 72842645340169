@import '../../assets/sass/theme';

.dot {
  height: 8px;
  width: 8px;
  background-color: rgb(233, 233, 233);
  border-radius: 50%;
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
}

.darkdot {
  height: 8px;
  width: 8px;
  background-color: rgb(23, 194, 143);
  border-radius: 50%;
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
}
