@import '../../assets/sass/theme';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');

.smallTitle {
  font-weight: normal;
  font-size: 14pt;
  margin-top: 35px;
}

.analyticGraph {
  width: 100%;
  max-width: 22vw;
  padding: 1vw;
  box-shadow: 5px 5px 5px $blaise-light-gray;
}

.percentGraph {
  width: 100%;
  max-width: 20vw;
  padding: 1vw;
  box-shadow: 5px 5px 5px $blaise-light-gray;
}

.longtermGraph {
  width: 100%;
  max-width: 70vw;
  padding: 1vw;
  box-shadow: 5px 5px 5px $blaise-light-gray;
}

.header-container {
  margin-bottom: 20px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-title {
  font-weight: normal;
  font-size: 20pt;
}
