@import '../../assets/sass/theme';

#profilePic {
  width: 5vw;
  min-width: 60px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 2px -2px rgba(0, 0, 0, 0.12);
  border-radius: 5vw;
}

.profileName {
  font-weight: bolder;
  font-size: 22pt;
  color: $black;
}

.agencySubtitle {
  color: $blaise-gray;
  font-size: 14pt;
}

.profileTitle {
  color: $black;
  font-size: 16pt;
}

// .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded {
//     border-radius: 30px;
// }

.profileHeadings {
  text-align: right;
  color: $blaise-gray;
  line-height: 3;
  font-size: 12pt;
}

.profileValues {
  line-height: 3;
  font-size: 12pt;
}

.profileDetails {
  max-width: 800px;
}
