@import '../../../assets/sass/theme';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');

.tableWrapper {
  overflow: auto;
  padding: 20px;
}

.tableBorder {
  padding: 15px;
  overflow-x: auto;
}

#cancelButton {
  text-transform: none;
  border-radius: 10px;
  font-size: 12pt;
  padding: 5px 40px;
}

#confirmButton {
  text-transform: none;
  border-radius: 10px;
  color: $white;
  font-size: 12pt;
  padding: 5px 40px;
}

.emptyBox {
  margin-top: 20px;
}

.deleteButton {
  background-color: $red !important;
}
