@import './../../assets/sass/theme';

.pass-container {
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #0000003b;
  border-radius: 6px;
}

.pass-container-loading {
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0000003b;
  border-radius: 6px;
}

.icon-container {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  margin-bottom: 8px;
}

.consent-from-passenger-container {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 24px;
}
