@import '../../assets/sass/theme';

// Overwriting Scheduler package's date picker font size.
.header2-text-label {
  font-size: 16px;
}

// Overwriting Scheduler's styling
.scheduler {
  margin: 0;
}

// Overwriting Scheduler's styling
.ant-row-flex.ant-row-flex-space-between.ant-row-flex-middle {
  margin-bottom: 0 !important;
  padding: 16px 0;
}

.driverShiftsModal {
  width: 100%;
  max-width: 650px;
  max-height: calc(100vh - 100px);
  background-color: $white;
  outline: 0;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.inputLabel {
  font-size: 1rem;
  font-weight: bold;
  color: $gray-700;
  margin: 0;
}

#editDriverShiftModal h2 {
  margin: 0;
  font-size: 1.2em;
}

// Overwriting MUI Toggle Button
.MuiToggleButton-root.Mui-selected {
  color: $button-grey-text !important;
  background-color: $blaise-green !important;

  &:hover {
    color: $button-grey-text !important;
  }
}
