@import '../../assets/sass/theme';

// Styles link like our primary buttons
.viewReceipt {
  text-decoration: none;
  padding: 0 16px;
  background-color: $blaise-green;
  color: $button-grey-text;
  border-radius: 4px;
  height: 53.63px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgb(0, 143, 121);
  }
}
