.pickup-dropoff-container {
  position: relative;

  .solid-line {
    position: absolute;
    top: 26px;
    left: -26px; // 24px padding right + 2px border line
    height: 164px;
    border-width: 1px;
    border-style: solid;
  }

  .dot {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    background-color: white;
    position: absolute;
    left: -37px;
    z-index: 1;
  }
}

.trip-details-icon-container {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 28px 0;
}
