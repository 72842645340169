@import '../../assets/sass/theme';
@import '../../App.scss';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');

#agencyLogo {
  width: 5vw;
  min-width: 60px;
}

.agencyTitle {
  color: $blaise-gray;
  margin: 0 10px;
  font-size: 18px;
}
