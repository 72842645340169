@import '../../assets/sass/theme';

// Making the tabs button container full width and centered.
.tabs-container {
  div[role='tablist'] {
    justify-content: center;
  }
}

.icon-container {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  margin-bottom: 8px;
}

.ticket-bundle-plans-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding-top: 16px;
}

.pay-with-blaise-balance {
  width: 100%;
  background-color: $gray-200 !important;
  padding: 32px 0 !important;
  border: 2px solid transparent !important;

  &.insufficient-funds {
    background-color: #cc493c1a !important;
    border: 2px solid $red !important;
  }
}

.insuficient-funds-warning {
  margin-top: 4px !important;
  color: $red;
  font-size: 14px !important;
}

.ticket-title {
  font-weight: bold !important;
  margin-top: 32px !important;
  margin-bottom: 24px !important;
}

.blaise-balance-purchase-summary {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #edf8f5;
  padding: 24px 0;
  margin-top: 32px;
}

.consent-from-passenger-container {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 16px;
}

.ticket-bundle-container {
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #0000003b;
  border-radius: 6px;
}

.sufficient-balance {
  font-weight: bold;
  padding: 24px 0;
  background-color: $gray-200 !important;
  border: 2px solid $blaise-light-gray;
  border-radius: 4px;
  text-align: center;
}

.insufficient-balance {
  font-weight: bold;
  padding: 24px 0;
  background-color: rgba(247, 67, 70, 0.1);
  border: 2px solid $blaise-red;
  border-radius: 4px;
  text-align: center;
}
