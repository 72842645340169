@import '../../assets/sass/theme';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');

.tableWrapper {
  overflow: auto;
  padding: 20px 0;
  margin-top: -20px;
}

.exportBtn {
  box-sizing: border-box;
  width: 112px;
  height: 40px;
  background-color: #ffffff;
  overflow: visible;
  border-radius: 4px;
  border: 1px solid #404040;
}

.startAndEndTime .MuiFormControl-root {
  min-width: 100%;
}

.startAndEndTime input {
  padding: 14px 12px;
}

.paginateBox {
  min-width: 220px;
  margin-bottom: -15px;
}

.paginateBox .MuiTableCell-root {
  border-bottom: 0;
}
