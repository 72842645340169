@import '../../../assets/sass/theme';

.recurrentModal {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 100px);
}

.overlapText {
  color: $gray-600;
  font-size: 14px;
  font-weight: 600;
}

.overlapLabels {
  color: $gray-500;
  font-size: 14px;
  font-weight: 600;
}

.shiftDetailsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: $gray-100;
  padding: 0.5rem 1.5rem;
  border-top: 1px solid $gray-300;
}

.backBtn {
  color: $gray-600 !important;
  border: 1px solid $gray-300 !important;
  height: 36px !important;
  border-radius: 4px !important;
}

// Overwriting toggle button styles to match other inputs
.MuiToggleButton-root {
  color: rgba(0, 0, 0, 0.54) !important;
}
