@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.logoNavbar{width:180px;padding:20px}.logoutBox{margin-top:auto}.navBox{height:100vh}#logoutButton{color:#a23939;border-color:#a23939;text-transform:none;font-family:'Source Sans Pro', sans-serif;margin:20px 40px}.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft{border:none}.selectedNavbarItem{background-color:#fff !important;color:#00cdad !important;border-radius:30px 0 0 30px}

#cancelButton{text-transform:none;border-radius:10px;font-size:12pt;padding:5px 40px;margin:10px}#confirmButton{text-transform:none;border-radius:10px;color:#fff;font-size:12pt;padding:5px 40px;margin:10px}

.tableWrapper{overflow:auto;padding:20px}.tableBorder{padding:15px;overflow-x:auto}#cancelButton{text-transform:none;border-radius:10px;font-size:12pt;padding:5px 40px}#confirmButton{text-transform:none;border-radius:10px;color:#fff;font-size:12pt;padding:5px 40px}.emptyBox{margin-top:20px}.deleteButton{background-color:#a23939 !important}

button.MuiButtonBase-root.MuiIconButton-root.MuiPickersClock-pmButton,button.MuiButtonBase-root.MuiIconButton-root.MuiPickersClock-amButton{padding:0;width:40px;height:40px}

.modal{display:flex;justify-content:center;align-items:center}

.MuiInputBase-input{font-size:14px !important}.MuiPaper-rounded>ul>li{font-size:14px}

.tableWrapper{overflow:auto;padding:20px 0;margin-top:-20px}.exportBtn{box-sizing:border-box;width:112px;height:40px;background-color:#ffffff;overflow:visible;border-radius:4px;border:1px solid #404040}.startAndEndTime .MuiFormControl-root{min-width:100%}.startAndEndTime input{padding:14px 12px}.paginateBox{min-width:220px;margin-bottom:-15px}.paginateBox .MuiTableCell-root{border-bottom:0}

.header2-text-label{font-size:16px}.scheduler{margin:0}.ant-row-flex.ant-row-flex-space-between.ant-row-flex-middle{margin-bottom:0 !important;padding:16px 0}.driverShiftsModal{width:100%;max-width:650px;max-height:calc(100vh - 100px);background-color:#fff;outline:0;border-radius:8px;position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);overflow:hidden}.inputLabel{font-size:1rem;font-weight:bold;color:#374151;margin:0}#editDriverShiftModal h2{margin:0;font-size:1.2em}.MuiToggleButton-root.Mui-selected{color:#2b2a2a !important;background-color:#00cdad !important}.MuiToggleButton-root.Mui-selected:hover{color:#2b2a2a !important}

.recurrentModal{display:flex;flex-direction:column;max-height:calc(100vh - 100px)}.overlapText{color:#4b5563;font-size:14px;font-weight:600}.overlapLabels{color:#6b7280;font-size:14px;font-weight:600}.shiftDetailsRow{display:flex;flex-direction:row;align-items:center;background:#f3f4f6;padding:0.5rem 1.5rem;border-top:1px solid #d1d5db}.backBtn{color:#4b5563 !important;border:1px solid #d1d5db !important;height:36px !important;border-radius:4px !important}.MuiToggleButton-root{color:rgba(0,0,0,0.54) !important}

.tableWrapper{overflow-x:scroll;margin-top:-20px}.tripsSearch input{width:200px}.tripsSearch label{font-size:0.9em}.wideCell{min-width:90px;padding-left:15px}.narrowCell{min-width:25px}.paginateBox{min-width:200px}tr{cursor:pointer}.tableHeader th{margin:0;padding:0 0 0 15px}table{background-color:white;margin-top:35px}

.viewReceipt{text-decoration:none;padding:0 16px;background-color:#00cdad;color:#2b2a2a;border-radius:4px;height:53.63px;transition:background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;display:flex;align-items:center;justify-content:center}.viewReceipt:hover{background-color:#008f79}

.pickup-dropoff-container{position:relative}.pickup-dropoff-container .solid-line{position:absolute;top:26px;left:-26px;height:164px;border-width:1px;border-style:solid}.pickup-dropoff-container .dot{height:16px;width:16px;border-radius:50%;border-width:2px;border-style:solid;background-color:white;position:absolute;left:-37px;z-index:1}.trip-details-icon-container{display:flex;align-items:center;grid-gap:20px;gap:20px;padding:28px 0}

.map-key-container{font-size:14px !important;display:flex;align-items:center;color:#7a7a7a;font-weight:400;line-height:1.75}.map-key-container .map-key-circle{height:10px;width:10px;border-radius:50%;margin-right:6px}

.dot{height:8px;width:8px;background-color:#e9e9e9;border-radius:50%;display:inline-block;margin-left:2px;margin-right:2px}.darkdot{height:8px;width:8px;background-color:#17c28f;border-radius:50%;display:inline-block;margin-left:2px;margin-right:2px}

.pass-container{padding:24px;width:100%;display:flex;flex-direction:column;border:1px solid #0000003b;border-radius:6px}.pass-container-loading{width:100%;height:160px;display:flex;justify-content:center;align-items:center;border:1px solid #0000003b;border-radius:6px}.icon-container{height:24px;width:24px;display:flex;justify-content:center;align-items:center;margin-right:16px;margin-bottom:8px}.consent-from-passenger-container{border:1px solid rgba(0,0,0,0.23);border-radius:4px;padding:24px}

.tabs-container div[role='tablist']{justify-content:center}.icon-container{height:24px;width:24px;display:flex;justify-content:center;align-items:center;margin-right:16px;margin-bottom:8px}.ticket-bundle-plans-container{display:flex;flex-direction:column;justify-content:space-between;align-items:center;grid-gap:24px;gap:24px;padding-top:16px}.pay-with-blaise-balance{width:100%;background-color:#e5e7eb !important;padding:32px 0 !important;border:2px solid transparent !important}.pay-with-blaise-balance.insufficient-funds{background-color:#cc493c1a !important;border:2px solid #a23939 !important}.insuficient-funds-warning{margin-top:4px !important;color:#a23939;font-size:14px !important}.ticket-title{font-weight:bold !important;margin-top:32px !important;margin-bottom:24px !important}.blaise-balance-purchase-summary{width:100%;display:flex;justify-content:center;background-color:#edf8f5;padding:24px 0;margin-top:32px}.consent-from-passenger-container{border:1px solid rgba(0,0,0,0.23);border-radius:4px;padding:24px;margin-bottom:16px}.ticket-bundle-container{padding:24px;width:100%;display:flex;flex-direction:column;border:1px solid #0000003b;border-radius:6px}.sufficient-balance{font-weight:bold;padding:24px 0;background-color:#e5e7eb !important;border:2px solid #e6e6e6;border-radius:4px;text-align:center}.insufficient-balance{font-weight:bold;padding:24px 0;background-color:rgba(247,67,70,0.1);border:2px solid #f94346;border-radius:4px;text-align:center}

.ticket-bundle{width:100%;background-color:#e5e7eb !important;display:flex !important;justify-content:space-between !important;padding:25px 24px !important;border:2px solid transparent !important}.selected-ticket-bundle{background-color:#02cdac26 !important;border:2px solid #00cdad !important}.selected-ticket-bundle:hover{background-color:#02cdac26}

#tripModal{width:700px;max-height:90vh;overflow:auto;padding:35px 40px;background-color:#fff;outline:0;border-radius:15px;position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%)}#mapModal{width:700px;max-height:90vh;overflow:auto;padding:35px 40px;background-color:#fff;outline:0;border-radius:15px;position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%)}#tripModal h2{margin:0;font-size:1.2em}#notes{width:100%;border-color:#c4c4c4;border-radius:5px;padding:0 8px;font-family:'Source Sans Pro';font-size:16px;outline:none}.MuiSelect-select:focus{background-color:#fff !important}.legendChart{width:35%}.legendChart td{border:none}@media screen and (max-width: 1920px){.legendChart{width:50%}}@media screen and (max-width: 1280px){.legendChart{width:70%}}@media screen and (max-width: 960px){.legendChart{width:100%}}#trip_cancellation_confirmation_modal{width:700px;max-height:90vh;overflow:auto;padding:35px 40px;background-color:#fff;outline:0;border-radius:15px;position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%)}#trip_cancellation_confirmation_modal h2{margin:0;font-size:1.2em}

#profilePic{width:5vw;min-width:60px;box-shadow:0px 2px 4px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 2px -2px rgba(0,0,0,0.12);border-radius:5vw}.profileName{font-weight:bolder;font-size:22pt;color:#000}.agencySubtitle{color:#7a7a7a;font-size:14pt}.profileTitle{color:#000;font-size:16pt}.profileHeadings{text-align:right;color:#7a7a7a;line-height:3;font-size:12pt}.profileValues{line-height:3;font-size:12pt}.profileDetails{max-width:800px}

body{font-family:'Source Sans Pro', sans-serif !important}.pageTitle{font-weight:normal;font-size:20pt;margin-bottom:20px}#pageWrap{margin-left:230px;padding:30px}.blaise-progress-top{margin:20px 0px;text-align:center}#agencyLogo{width:5vw;min-width:60px}.agencyTitle{color:#7a7a7a;margin:0 10px;font-size:18px}

input[type='number']::-webkit-inner-spin-button,input[type='number']::-webkit-outer-spin-button,input[type='time']::-webkit-inner-spin-button,input[type='time']::-webkit-outer-spin-button{-webkit-appearance:none;margin:0}#saveButton{color:#fff;background-color:#00cdad;text-transform:none;font-family:'Source Sans Pro', sans-serif;font-size:16px;padding:4px 16px;margin:20px auto;text-align:center}.formBox{max-width:550px;min-width:260px;width:18vw}.MuiButtonBase-root.MuiButton-root.MuiButton-contained{text-align:center}.formPaper.MuiPaper-rounded{border-radius:30px;height:100%;box-sizing:border-box}.formIcons{margin:20px 0px 10px 17px}.formIcons:hover{color:#00cdad}.MuiPaper-root.MuiPaper-elevation8.MuiPopover-paper.MuiPaper-rounded{background-color:#f2faf8}.tooltip .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded{width:300px}#setupModal{width:700px;max-height:90vh;overflow:auto;padding:35px 40px;background-color:#fff;outline:0;border-radius:15px;position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%)}#setupModal h2{margin:0;font-size:1.2em}

.Dropzone{height:100px;background-color:#fff;border:2px dashed #bbbaba;display:flex;align-items:center;justify-content:center;flex-direction:column;font-size:16px}.Highlight{background-color:#f2faf8}.FileInput{display:none}

.ProgressBar{width:100%;height:8px;background-color:#e6e6e6;border-radius:5px}.Progress{background-color:#00cdad;height:100%;margin:0;border-radius:5px}

.Upload{display:flex;flex-direction:column;flex:1 1;align-items:flex-start;text-align:left;overflow:hidden}.Content{display:flex;flex-direction:column;padding-top:16px;box-sizing:border-box;width:100%}.Files{align-items:flex-start;justify-items:flex-start;flex:1 1;overflow-y:auto}.Actions{padding-top:10px;display:flex;flex:1 1;width:100%;justify-content:flex-end;flex-direction:row}.Title{color:#555;font-weight:bold}.Filename{margin-bottom:8px;font-size:16px;color:#555}.Row{display:flex;flex:1 1;flex-direction:row;justify-content:space-between;align-items:center;height:50px;padding:8px;overflow:hidden;box-sizing:border-box}.RowItem{padding-right:5px}.ProgressWrapper{display:flex;flex:1 1;flex-direction:row;align-items:center;justify-content:space-around}

#map{width:45vw;justify-content:right;border-radius:30px;box-shadow:0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12);margin:10px auto 10px auto}#modify-button{color:#fff;background-color:#00cdad;text-transform:none;font-family:'Source Sans Pro', sans-serif;font-size:16px;padding:5px 35px;margin:10px 0px;border-radius:20px;text-align:center;width:300px;margin:10px auto}#download-button{color:#fff;background-color:#00cdad;text-transform:none;font-family:'Source Sans Pro', sans-serif;font-size:16px;padding:5px 35px;border-radius:20px;text-align:center;margin-top:185px}.formPaper.MuiPaper-rounded{border-radius:30px}#operating_zone{margin-left:20px;width:50vw}

body{font-family:'Source Sans Pro', sans-serif !important}.pageTitle{font-weight:normal;font-size:20pt;margin-bottom:20px}#pageWrap{margin-left:230px;padding:30px}.blaise-progress-top{margin:20px 0px;text-align:center}

.container{height:100vh;width:100vw;display:flex;justify-content:center;align-items:center}.container .logo{color:#00cdad}

.smallTitle{font-weight:normal;font-size:14pt;margin-top:35px}.analyticGraph{width:100%;max-width:22vw;padding:1vw;box-shadow:5px 5px 5px #e6e6e6}.percentGraph{width:100%;max-width:20vw;padding:1vw;box-shadow:5px 5px 5px #e6e6e6}.longtermGraph{width:100%;max-width:70vw;padding:1vw;box-shadow:5px 5px 5px #e6e6e6}.header-container{margin-bottom:20px !important;display:flex;align-items:center;justify-content:space-between}.page-title{font-weight:normal;font-size:20pt}

.details-container{width:100%;margin-top:32px;display:flex;box-sizing:border-box}.details-container .dashed-line-container{padding:26px 24px 26px 0}.details-container .dashed-line-container .dashed-line{height:100%;border-left:2px dashed #d1d5db;position:relative}.details-container .dashed-line-container .dashed-line .origin-dot{height:16px;width:16px;border-radius:50%;background-color:#52479c;position:absolute;top:-10px;left:-10px}.details-container .dashed-line-container .dashed-line .destination-dot{position:absolute;bottom:-10px;left:-9px}.details-container .inputs-container{display:flex;flex-direction:column;flex-grow:1}.map-key-container{font-size:14px !important;display:flex;align-items:center;color:#7a7a7a;font-weight:400;line-height:1.75}.map-key-container .map-key-circle{height:10px;width:10px;border-radius:50%;margin-right:6px}

