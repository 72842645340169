@import '../../assets/sass/theme';

.Upload {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
}

.Content {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  box-sizing: border-box;
  width: 100%;
}

.Files {
  align-items: flex-start;
  justify-items: flex-start;
  flex: 1;
  overflow-y: auto;
}

.Actions {
  padding-top: 10px;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: flex-end;
  flex-direction: row;
}

.Title {
  color: #555;
  font-weight: bold;
}

.Filename {
  margin-bottom: 8px;
  font-size: 16px;
  color: #555;
}

.Row {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 8px;
  overflow: hidden;
  box-sizing: border-box;
}

.RowItem {
  padding-right: 5px;
}

.ProgressWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
