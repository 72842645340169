@import '../../../assets/sass/theme';

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button,
input[type='time']::-webkit-inner-spin-button,
input[type='time']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#saveButton {
  color: $white;
  background-color: $blaise-green;
  text-transform: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  padding: 4px 16px;
  margin: 20px auto;
  text-align: center;
}

.formBox {
  max-width: 550px;
  min-width: 260px;
  width: 18vw;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  text-align: center;
}

.formPaper.MuiPaper-rounded {
  border-radius: 30px;
  height: 100%;
  box-sizing: border-box;
}

.formIcons {
  margin: 20px 0px 10px 17px;
}

.formIcons:hover {
  color: $blaise-green;
}

.MuiPaper-root.MuiPaper-elevation8.MuiPopover-paper.MuiPaper-rounded {
  background-color: $blaise-light-green;
}

.tooltip {
  .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    width: 300px;
  }
}

#setupModal {
  width: 700px;
  max-height: 90vh;
  overflow: auto;
  padding: 35px 40px;
  background-color: $white;
  outline: 0;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#setupModal h2 {
  margin: 0;
  font-size: 1.2em;
}
