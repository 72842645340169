@import '../../assets/sass/theme';

.Dropzone {
  height: 100px;
  background-color: #fff;
  border: 2px dashed rgb(187, 186, 186);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
}

.Highlight {
  background-color: $blaise-light-green;
}

.FileInput {
  display: none;
}
