@import '../../assets/sass/theme';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');

.tableWrapper {
  overflow-x: scroll;
  margin-top: -20px;
}

.tripsSearch input {
  width: 200px;
}

.tripsSearch label {
  font-size: 0.9em;
}

.wideCell {
  min-width: 90px;
  padding-left: 15px;
}

.narrowCell {
  min-width: 25px;
}

.paginateBox {
  min-width: 200px;
}

tr {
  cursor: pointer;
}

.tableHeader th {
  margin: 0;
  padding: 0 0 0 15px;
}

table {
  background-color: white;

  margin-top: 35px;
}
