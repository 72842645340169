@import '../../assets/sass/theme';

#tripModal {
  width: 700px;
  max-height: 90vh;
  overflow: auto;
  padding: 35px 40px;
  background-color: $white;
  outline: 0;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#mapModal {
  width: 700px;
  max-height: 90vh;
  overflow: auto;
  padding: 35px 40px;
  background-color: $white;
  outline: 0;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#tripModal h2 {
  margin: 0;
  font-size: 1.2em;
}

#notes {
  width: 100%;
  border-color: #c4c4c4;
  border-radius: 5px;
  padding: 0 8px;
  font-family: 'Source Sans Pro';
  font-size: 16px;
  outline: none;
}

.MuiSelect-select:focus {
  background-color: $white !important;
}

.legendChart {
  width: 35%;

  td {
    border: none;
  }
}

@media screen and (max-width: 1920px) {
  .legendChart {
    width: 50%;
  }
}

@media screen and (max-width: 1280px) {
  .legendChart {
    width: 70%;
  }
}

@media screen and (max-width: 960px) {
  .legendChart {
    width: 100%;
  }
}

#trip_cancellation_confirmation_modal {
  width: 700px;
  max-height: 90vh;
  overflow: auto;
  padding: 35px 40px;
  background-color: $white;
  outline: 0;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#trip_cancellation_confirmation_modal h2 {
  margin: 0;
  font-size: 1.2em;
}
