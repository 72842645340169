@import '../../assets/sass/theme';

#cancelButton {
  text-transform: none;
  border-radius: 10px;
  font-size: 12pt;
  padding: 5px 40px;
  margin: 10px;
}

#confirmButton {
  text-transform: none;
  border-radius: 10px;
  color: $white;
  font-size: 12pt;
  padding: 5px 40px;
  margin: 10px;
}
