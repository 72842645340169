@import '../../../assets/sass/theme';

#map {
  width: 45vw;
  justify-content: right;
  border-radius: 30px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin: 10px auto 10px auto;
}

#modify-button {
  color: $white;
  background-color: $blaise-green;
  text-transform: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  padding: 5px 35px;
  margin: 10px 0px;
  border-radius: 20px;
  text-align: center;
  width: 300px;
  margin: 10px auto;
}

#download-button {
  color: $white;
  background-color: $blaise-green;
  text-transform: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  padding: 5px 35px;
  border-radius: 20px;
  text-align: center;
  margin-top: 185px; // TODO: make it dynamic
}

.formPaper.MuiPaper-rounded {
  border-radius: 30px;
}

#operating_zone {
  margin-left: 20px;
  width: 50vw;
}
