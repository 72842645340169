@import '../../assets/sass/theme';

.details-container {
  width: 100%;
  margin-top: 32px;
  display: flex;
  box-sizing: border-box;

  .dashed-line-container {
    padding: 26px 24px 26px 0;

    .dashed-line {
      height: 100%;
      border-left: 2px dashed $gray-300;
      position: relative;

      .origin-dot {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: $blaise-purple;
        position: absolute;
        top: -10px;
        left: -10px;
      }

      .destination-dot {
        position: absolute;
        bottom: -10px;
        left: -9px;
      }
    }
  }

  .inputs-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.map-key-container {
  font-size: 14px !important;
  display: flex;
  align-items: center;
  color: $blaise-gray;
  font-weight: 400;
  line-height: 1.75;

  .map-key-circle {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 6px;
  }
}
